import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user_auth_token: null,
  },
  mutations: {
    set_user_auth_token: (state, token) => {
      localStorage.user_auth_token = token;
      state.user_auth_token = token;
    },
    clear_user_auth_token: (state) => {
      localStorage.removeItem("user_auth_token");
      state.user_auth_token = null;
    },
  },
});
