import axios from "../utils/http";

const invoices = {
  list(params) {
    return axios.post("invoices/list", params);
  },
  recharge(params) {
    return axios.post("invoices/recharge", params);
  },
  recharge_basic_info() {
    return axios.post("invoices/recharge_basic_info");
  },
  invoice_info(params) {
    return axios.post("invoices/invoice_info", params);
  },
};

export default invoices;
