import axios from "../utils/http";

const articles = {
  list(params) {
    return axios.post("articles/list", params);
  },
  query(id) {
    return axios.post("articles/query", {
      id: id,
    });
  },
};

export default articles;
