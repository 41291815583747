import axios from "../utils/http";

const user = {
  login(email, password) {
    return axios.post("user/login", {
      email: email,
      password: password,
    });
  },
  login_from_manager(u_m) {
    return axios.post("user/login_from_manager", {
      u_m: u_m,
    });
  },
  register(captcha, invite_code, user) {
    return axios.post("user/register", {
      captcha: captcha,
      invite_code: invite_code,
      user: user,
    });
  },
  basic_info() {
    return axios.post("user/basic_info");
  },
  update_basic_info(user) {
    return axios.post("user/update_basic_info", {
      user: user,
    });
  },
  change_password(form_data) {
    return axios.post("user/change_password", {
      form_data: form_data,
    });
  },
  forget_password(captcha, email) {
    return axios.post("user/forget_password", {
      captcha: captcha,
      email: email,
    });
  },
  check_reset_password_sign(sign) {
    return axios.post("user/check_reset_password_sign", {
      sign,
    });
  },
  reset_password(sign, password) {
    return axios.post("user/reset_password", {
      sign,
      password,
    });
  },
};

export default user;
