import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

// 防止重复跳转报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "root",
    component: () => import("../views/dashboard/IndexV2.vue"),
    // component: () => import('../views/dashboard/Index.vue'),
    meta: { headerNavType: "root", title: "首页" },
  },
  {
    path: "/v2",
    name: "root_v2",
    component: () => import("../views/dashboard/IndexV2.vue"),
    meta: { headerNavType: "root", title: "我的货物 V2" },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/dashboard/Login.vue"),
    meta: { skipAuth: true, title: "用户登录" },
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/dashboard/Register.vue"),
    meta: { skipAuth: true, title: "用户注册" },
  },
  {
    path: "/forget_password",
    name: "forget_password",
    component: () => import("../views/dashboard/ForgetPassword.vue"),
    meta: { skipAuth: true, title: "找回密码" },
  },
  {
    path: "/reset_password",
    name: "reset_password",
    component: () => import("../views/dashboard/ResetPassword.vue"),
    meta: { skipAuth: true, title: "重置密码" },
  },
  {
    path: "/account",
    name: "account",
    component: () => import("../views/dashboard/Account.vue"),
    meta: { title: "编辑账户信息" },
  },
  {
    path: "/change_password",
    name: "change_password",
    component: () => import("../views/dashboard/ChangePassword.vue"),
    meta: { title: "修改登录密码" },
  },
  {
    path: "/inbounds",
    name: "inbounds",
    component: () => import("../views/inbounds/Index.vue"),
    meta: { headerNavType: "inbounds", title: "我的货物" },
  },
  {
    path: "/inbounds/new",
    name: "inbounds_new",
    component: () => import("../views/inbounds/New.vue"),
    meta: { headerNavType: "inbounds", title: "提交入库" },
  },
  // {
  //   path: '/inbounds/pending',
  //   name: 'inbounds_pending',
  //   component: () => import('../views/inbounds/Pending.vue'),
  //   meta: { headerNavType: 'inbounds', title: '待入库包裹' }
  // },
  // {
  //   path: '/inbounds/received',
  //   name: 'inbounds_received',
  //   component: () => import('../views/inbounds/Received.vue'),
  //   meta: { headerNavType: 'inbounds', title: '已入库包裹' }
  // },
  {
    path: "/inbounds/finish_manager_created_inbound/:id",
    name: "inbounds_finish_manager_created_inbound",
    component: () =>
      import("../views/inbounds/FinishManagerCreatedInbound.vue"),
    meta: { headerNavType: "inbounds", title: "完善管理员协助入库包裹信息" },
  },
  {
    path: "/inbounds/edit/:id",
    name: "inbounds_edit_inbound",
    component: () => import("../views/inbounds/Edit.vue"),
    meta: { headerNavType: "inbounds", title: "编辑入库信息" },
  },
  {
    path: "/inbounds/:id",
    name: "inbounds_show",
    component: () => import("../views/inbounds/Show.vue"),
    meta: { headerNavType: "inbounds", title: "查看入库包裹" },
  },
  {
    path: "/outbound_groups",
    name: "outbound_groups",
    component: () => import("../views/outbound_groups/Index.vue"),
    meta: { headerNavType: "outbound_groups", title: "我的拼团" },
  },
  {
    path: "/outbound_groups/new",
    name: "outbound_groups_new",
    component: () => import("../views/outbound_groups/New.vue"),
    meta: { headerNavType: "outbound_groups", title: "新增拼团" },
  },
  {
    path: "/outbound_groups/join",
    name: "outbound_groups_join",
    component: () => import("../views/outbound_groups/Join.vue"),
    meta: { headerNavType: "outbound_groups", title: "加入拼团" },
  },
  {
    path: "/outbound_groups/charge_outbound/:id",
    name: "outbound_groups_charge_outbound",
    component: () => import("../views/outbound_groups/ChargeOutbound.vue"),
    meta: { headerNavType: "outbound_groups", title: "拼团出库缴费" },
  },
  {
    path: "/outbound_groups/edit/:id",
    name: "outbound_groups_edit",
    component: () => import("../views/outbound_groups/Edit.vue"),
    meta: { headerNavType: "outbound_groups", title: "编辑拼团" },
  },
  {
    path: "/outbound_groups/:id",
    name: "outbound_groups_show",
    component: () => import("../views/outbound_groups/Show.vue"),
    meta: { headerNavType: "outbound_groups", title: "查看拼团" },
  },
  {
    path: "/outbounds",
    name: "outbounds",
    component: () => import("../views/outbounds/Index.vue"),
    meta: { headerNavType: "outbounds", title: "我的订单" },
  },
  // {
  //   path: '/outbounds/new',
  //   name: 'outbounds_new',
  //   component: () => import('../views/outbounds/New.vue'),
  //   meta: { headerNavType: 'outbounds', title: '提交出库' }
  // },
  {
    path: "/outbounds/new_v2",
    name: "outbounds_new_v2",
    component: () => import("../views/outbounds/NewV2.vue"),
    meta: { headerNavType: "outbounds", title: "提交出库" },
  },
  // {
  //   path: '/outbounds/pending',
  //   name: 'outbounds_pending',
  //   component: () => import('../views/outbounds/Pending.vue'),
  //   meta: { headerNavType: 'outbounds', title: '待缴费订单' }
  // },
  {
    path: "/outbounds/charge_outbound/:id",
    name: "outbounds_charge_outbound",
    component: () => import("../views/outbounds/ChargeOutbound.vue"),
    meta: { headerNavType: "outbounds", title: "出库订单缴费" },
  },
  // {
  //   path: '/outbounds/outbounded',
  //   name: 'outbounds_outbounded',
  //   component: () => import('../views/outbounds/Outbounded.vue'),
  //   meta: { headerNavType: 'outbounds', title: '已出库订单' }
  // },
  // {
  //   path: '/outbounds/arrived',
  //   name: 'outbounds_arrived',
  //   component: () => import('../views/outbounds/Arrived.vue'),
  //   meta: { headerNavType: 'outbounds', title: '到达海外订单' }
  // },
  {
    path: "/outbounds/:id",
    name: "outbounds_show",
    component: () => import("../views/outbounds/Show.vue"),
    meta: { headerNavType: "outbounds", title: "查看出库订单" },
  },
  {
    path: "/invoices",
    name: "invoices",
    component: () => import("../views/invoices/Index.vue"),
    meta: { headerNavType: "invoices", title: "我的账户" },
  },
  {
    path: "/invoices/recharge",
    name: "invoices_recharge",
    component: () => import("../views/invoices/Recharge.vue"),
    meta: { headerNavType: "invoices", title: "账户支付" },
  },
  {
    path: "/invoices/recharge_result",
    name: "invoices_recharge_result",
    component: () => import("../views/invoices/RechargeResult.vue"),
    meta: { headerNavType: "invoices", title: "账户支付结果" },
  },
  {
    path: "/articles",
    name: "articles",
    component: () => import("../views/articles/Index.vue"),
    meta: { skipAuth: true, headerNavType: "articles", title: "系统公告" },
  },
  {
    path: "/articles/:id",
    name: "article_show",
    component: () => import("../views/articles/Show.vue"),
    meta: { skipAuth: true, headerNavType: "articles", title: "系统公告" },
  },
  {
    path: "/guides",
    name: "guides",
    component: () => import("../views/guides/Index.vue"),
    meta: { skipAuth: true, headerNavType: "guides", title: "新手指南" },
  },
  {
    path: "/process_flow",
    name: "process_flow",
    component: () => import("../views/guides/ProcessFlow.vue"),
    meta: { skipAuth: true, headerNavType: "guides", title: "操作流程" },
  },
  {
    path: "/demo",
    name: "demo",
    component: () => import("../views/demo/Index.vue"),
    meta: { skipAuth: true },
  },
  {
    path: "/test",
    name: "test",
    component: () => import("../views/demo/Test.vue"),
    meta: { skipAuth: true },
  },
  {
    path: "/price_calculator",
    name: "price_calculator",
    component: () => import("../views/price_calculator/PriceCalculator.vue"),
    meta: {
      skipAuth: true,
      headerNavType: "price_calculator",
      title: "资费标准",
    },
  },
];

const router = new VueRouter({
  base: "/ucenter/",
  routes,
});

if (localStorage.user_auth_token) {
  store.commit("set_user_auth_token", localStorage.user_auth_token);
}

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title + " - 用户中心";
  } else {
    document.title = "用户中心";
  }
  if (to.meta.skipAuth) {
    next();
    return;
  }
  if (!store.state.user_auth_token) {
    next({
      name: "login",
      query: { redirect: to.fullPath },
    });
    return;
  }
  next();
});

export default router;
