import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

// 防止重复跳转报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "root",
    component: () => import("../views/splash/Index.vue"),
    meta: {},
  },
  {
    path: "/t/:code",
    name: "splash_outbound_group_show",
    component: () => import("../views/splash/OutboundGroupShow.vue"),
    meta: {},
  },
];

const router = new VueRouter({
  base: "/",
  routes,
});

if (localStorage.user_auth_token) {
  store.commit("set_user_auth_token", localStorage.user_auth_token);
}

router.beforeEach((to, from, next) => {
  if (to.meta.skipAuth) {
    next();
    return;
  }
  // if (!store.state.user_auth_token) {
  //   next({
  //     name: 'login',
  //     query: { redirect: to.fullPath }
  //   })
  //   return
  // }
  next();
});

export default router;
