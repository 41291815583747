import axios from "../utils/http";

const common = {
  hi() {
    return axios.post("hi");
  },
  captcha_check(captcha) {
    return axios.post("captcha/check", { captcha: captcha });
  },
};

export default common;
