import axios from "../utils/http";

const outbounds = {
  list(params) {
    return axios.post("outbounds/list", params);
  },
  check_outbound_inbounds(inbound_ids) {
    return axios.post("outbounds/check_outbound_inbounds", {
      inbound_ids: inbound_ids,
    });
  },
  create_with_inbounds(inbound_ids, outbound) {
    let params = {
      inbound_ids: inbound_ids,
      outbound: outbound,
    };
    return axios.post("outbounds/create_with_inbounds", params);
  },
  check_charge_options(outbound_id) {
    return axios.post("outbounds/check_charge_options", {
      id: outbound_id,
      outbound_type: "COMMON",
    });
  },
  group_outbound_check_charge_options(outbound_id) {
    return axios.post("outbounds/check_charge_options", {
      id: outbound_id,
      outbound_type: "GROUP",
    });
  },
  payment(outbound_id, payment_method) {
    return axios.post("outbounds/payment", {
      id: outbound_id,
      payment_method: payment_method,
      outbound_type: "COMMON",
    });
  },
  group_outbound_payment(outbound_id, payment_method) {
    return axios.post("outbounds/payment", {
      id: outbound_id,
      payment_method: payment_method,
      outbound_type: "GROUP",
    });
  },
  query(id) {
    return axios.post("outbounds/query", { id: id });
  },
  delete(params) {
    return axios.post("outbounds/delete", params);
  },
  apply_coupon_code(outbound_id, coupon_code) {
    return axios.post("outbounds/apply_coupon_code", {
      id: outbound_id,
      coupon_code: coupon_code,
    });
  },
};

export default outbounds;
