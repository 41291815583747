import axios from "axios";
import store from "../../store";
import router from "../../router";

axios.defaults.baseURL = "/ucenter/api/v1";
axios.defaults.timeout = 30000;
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded;charset=UTF-8";

axios.interceptors.request.use(
  (config) => {
    let token = store.state.user_auth_token;
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status == 401) {
      store.commit("clear_user_auth_token");
      router.replace({
        path: "/login",
        query: { redirect: router.currentRoute.fullPath },
      });
      return error.response;
    }
    return Promise.reject(error);
  }
);

export default axios;
