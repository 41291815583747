import axios from "../utils/http";

const price_calculator = {
  index() {
    return axios.post("price_calculator/index");
  },
  estimate_freight_price(params) {
    return axios.post("price_calculator/estimate_freight_price", params);
  },
};

export default price_calculator;
