import axios from "../utils/http";

const outbound_groups = {
  user_enable_outbound_group() {
    return axios.post("outbound_groups/user_enable_outbound_group");
  },
  list(params) {
    return axios.post("outbound_groups/list", params);
  },
  query(id) {
    return axios.post("outbound_groups/query", { id: id });
  },
  query_by_code(code) {
    return axios.post("outbound_groups/query", {
      query_by: "code",
      code: code,
    });
  },
  get_available_freight_rates() {
    return axios.post("outbound_groups/available_freight_rates");
  },
  create(params) {
    let outbound_group_params = {
      outbound_group: params.outbound_group,
    };
    return axios.post("outbound_groups/create", outbound_group_params);
  },
  edit_basic(params) {
    let outbound_group_params = {
      id: params.outbound_group.id,
      outbound_group: params.outbound_group,
    };
    return axios.post("outbound_groups/edit_basic", outbound_group_params);
  },
  join_inbounds(inbound_ids, outbound_group_id) {
    let params = {
      inbound_ids: inbound_ids,
      outbound_group_id: outbound_group_id,
    };
    return axios.post("outbound_groups/join_inbounds", params);
  },
  remove_inbounds(inbound_ids, outbound_group_id) {
    let params = {
      inbound_ids: inbound_ids,
      outbound_group_id: outbound_group_id,
    };
    return axios.post("outbound_groups/remove_inbounds", params);
  },
  cancel(id) {
    return axios.post("outbound_groups/cancel", { id: id });
  },
  begin_processing(id) {
    return axios.post("outbound_groups/begin_processing", { id: id });
  },
  follow(id) {
    return axios.post("outbound_groups/follow", { id: id });
  },
};

export default outbound_groups;
