import common from "./modules/common";
import dashboard from "./modules/dashboard";
import articles from "./modules/articles";
import inbounds from "./modules/inbounds";
import outbound_groups from "./modules/outbound_groups";
import outbounds from "./modules/outbounds";
import user from "./modules/user";
import invoices from "./modules/invoices";
import price_calculator from "./modules/price_calculator";

export default {
  common,
  dashboard,
  articles,
  inbounds,
  outbound_groups,
  outbounds,
  user,
  invoices,
  price_calculator,
};
