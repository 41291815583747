import axios from "../utils/http";

const inbounds = {
  express_carriers() {
    return axios.post("inbounds/express_carriers");
  },
  list(params) {
    return axios.post("inbounds/list", params);
  },
  create(params) {
    let inbound_params = {
      inbound: params.inbound,
      inbound_items: params.inbound_items,
    };
    return axios.post("inbounds/create", inbound_params);
  },
  finish_manager_created_inbound(params) {
    let inbound_params = {
      id: params.inbound.id,
      inbound: params.inbound,
      inbound_items: params.inbound_items,
    };
    return axios.post(
      "inbounds/finish_manager_created_inbound",
      inbound_params
    );
  },
  edit(params) {
    let inbound_params = {
      id: params.inbound.id,
      inbound: params.inbound,
      inbound_items: params.inbound_items,
    };
    return axios.post("inbounds/edit", inbound_params);
  },
  query(id) {
    return axios.post("inbounds/query", { id: id });
  },
  delete(params) {
    return axios.post("inbounds/delete", params);
  },
};

export default inbounds;
